<template>
  <HelloWorld />
</template>

<script>
import { defineComponent, computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

// Components
import HelloWorld from "../components/HelloWorld.vue";

export default defineComponent({
  name: "HomeView",

  components: {
    HelloWorld,
  },

  setup() {
    const siteData = reactive({
      title: "soft-developer.pro | Главная",
      description: "Разработка приложений OpenGL",
    });

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description),
        },
      ],
    });
  },
});
</script>
