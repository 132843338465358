<template>
  <v-app-bar app class="bg-light-blue-darken-3" prominent>
    <v-app-bar-title>
      <v-icon>mdi-bee</v-icon>
      <span>Soft developer</span>
    </v-app-bar-title>

    <v-spacer></v-spacer>

    <v-btn
      v-for="item in items"
      :key="item.key"
      :to="item.route"
      class="d-none d-sm-flex"
    >
      <v-icon v-if="item.isEnable">mdi-home</v-icon>
      <span :id="item.id">{{ item.title }}</span>
    </v-btn>

    <div style="width: 10px"></div>

    <v-app-bar-nav-icon
      variant="text"
      @click.stop="drawer = !drawer"
      class="d-flex d-sm-none"
    ></v-app-bar-nav-icon>
  </v-app-bar>

  <v-navigation-drawer v-model="drawer" location="bottom" temporary>
    <v-list-item v-for="item in items" :key="item.id" :to="item.route">
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "MainHeader",

  data() {
    return {
      drawer: false,
      group: null,
      items: [
        { id: "home", key: 1, title: "Главная", route: "/", isEnable: true },
        {
          id: "about",
          key: 2,
          title: "О себе",
          route: "/about",
          isEnable: false,
        },
        {
          id: "feedback",
          key: 3,
          title: "Обратная связь",
          route: "/feedback",
          isEnable: false,
        },
      ],
    };
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
