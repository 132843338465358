<template>
  <v-container>
    <MainHeader />

    <v-row class="text-center">
      <v-col cols="12">
        <div class="about">
          <h1>Home</h1>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";

// Components
import MainHeader from "../components/MainHeader.vue";

export default defineComponent({
  name: "HelloWorld",

  components: {
    MainHeader,
  },
});
</script>
